
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Loader',
    props: {
      logo: String
    },
    setup () {
      const loaderType = 'default' // | 'spinner-message' | 'spinner-logo'

      return {
        loaderType
      }
    }
  })
