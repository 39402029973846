
  import { defineComponent } from 'vue'
  import { useRoute } from 'vue-router'
  // import MainMenuConfig from '@/core/config/MainMenuConfig'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'

  export default defineComponent({
    name: 'KTMenuGlobal',
    components: {},
    props: {
      subdata: {
        type: Object,
        default () {
          return {}
        }
      },
      breadcrumbs: {
        type: Array,
        default: () => []
      }
    },
    setup () {
      const route = useRoute()

      const hasActiveChildren = (match) => route.path.indexOf(match) !== -1
      const headerMenuIcons = 'font'

      return {
        hasActiveChildren,
        headerMenuIcons,
        trans,
        transChoice,
        capitalize
      }
    }
  })
