
  import { defineComponent, computed, onMounted, watch, nextTick } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'

  // Custom modified theme imports
  import KTHeader from '@src/components/layout/header/Header.vue'
  import KTToolbar from '@src/components/layout/toolbar/Toolbar.vue'
  import KTActivityDrawer from '@src/components/layout/header/partials/ActivityDrawer.vue'
  import KTAside from '@src/components/layout/aside/Aside.vue'
  import HtmlClass from '@src/core/services/LayoutService'

  import { removeModalBackdrop } from '@src/core/helpers/dom'
  import KTLoader from '@src/components/Loader.vue'

  import { Actions } from '@src/store/enums/StoreEnums'
  import { MenuComponent } from '@src/assets/ts/components/index'
  import { reinitializeComponents } from '@src/core/plugins/keenthemes'

  // Modals
  import ModalTemplateAdd from '@src/components/modals/templates/Add.vue'

  export default defineComponent({
    name: 'Layout',
    components: {
      KTAside,
      KTHeader,
      KTToolbar,
      KTActivityDrawer,
      KTLoader,
      ModalTemplateAdd
    },
    setup () {
      const store = useStore()
      const route = useRoute()
      // const router = useRouter()

      // show page loading
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading')
      const tmp = document.querySelector('#modal_template_add')
      if (tmp !== null) {
        tmp.addEventListener('shown.bs.modal', () => {
          console.log('shown...')
        })
      }

      // initialize html element classes
      HtmlClass.init()

      const pageTitle = computed(() => store.getters.pageTitle)

      const breadcrumbs = computed(() => store.getters.pageBreadcrumbPath)

      onMounted(() => {
        // check if current user is authenticated
        // if (!store.getters.isUserAuthenticated) {
        //   router.push({ name: 'login' })
        // }

        nextTick(() => {
          reinitializeComponents()
        })

        // Simulate the delay page loading
        setTimeout(() => {
          // Remove page loader after some time
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
        }, 500)
      })

      watch(
        () => route.path,
        () => {
          MenuComponent.hideDropdowns(undefined)

          // check if current user is authenticated
          // if (!store.getters.isUserAuthenticated) {
          //   router.push({ name: 'login' })
          // }

          nextTick(() => {
            reinitializeComponents()
          })
          removeModalBackdrop()
        }
      )

      return {
        pageTitle,
        breadcrumbs
      }
    }
  })
