
  import { defineComponent, computed } from 'vue'
  import { useStore } from 'vuex'
  import settingsStore from '@src/store/enums/settings'
  
  import SubHeaderGlobal from '@src/components/layout/subheader/Global.vue'
  // import SubHeaderDashboard from '@src/components/layout/subheader/pages/Dashboard.vue'
  // import SubHeaderTasks from '@src/components/layout/subheader/pages/Tasks.vue'

  export default defineComponent({
    name: 'KToolbar',
    props: {
      breadcrumbs: Array,
      title: String
    },
    components: {
      SubHeaderGlobal
    },
    setup () {
      const toolbarWidthFluid = true

      const store = useStore()
      const getActiveSubmenu = computed(() => store.getters[settingsStore.Getters.GET_ACTIVE_SUBMENU])
      const getSubmenuData = computed(() => store.getters[settingsStore.Getters.GET_SUBMENU_DATA])

      console.log('getActiveSubmenu: ', getActiveSubmenu.value)
      return {
        toolbarWidthFluid,
        getActiveSubmenu,
        getSubmenuData
      }
    }
  })
