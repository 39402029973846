import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-3 me-5 fs-5 text-gray-500" }
const _hoisted_2 = {
  class: "header-menu align-items-center",
  "data-kt-drawer": "false",
  "data-kt-drawer-name": "header-menu",
  "data-kt-drawer-activate": "{default: true, lg: true}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_header_menu_mobile_toggle",
  "data-kt-place": "true",
  "data-kt-place-mode": "prepend",
  "data-kt-place-parent": "{default: '#kt_body', lg: '#kt_toolbar_container'}"
}
const _hoisted_3 = {
  class: "menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-center",
  id: "#kt_header_menu",
  "data-kt-menu": "true"
}
const _hoisted_4 = { class: "menu-item me-lg-1" }
const _hoisted_5 = { class: "menu-title" }
const _hoisted_6 = { class: "menu-item me-lg-1" }
const _hoisted_7 = { class: "menu-title" }
const _hoisted_8 = { class: "menu-item me-lg-1" }
const _hoisted_9 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.capitalize(_ctx.transChoice('global.shortcut', 2))) + ": ", 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            class: "menu-link",
            to: "/products",
            "active-class": "active"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.trans('nav.aside.products.all')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_router_link, {
            class: "menu-link",
            to: "/templates",
            "active-class": "active"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.trans('nav.aside.templates.all')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_router_link, {
            class: "menu-link",
            to: "/customers",
            "active-class": "active"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.trans('nav.aside.customers.all')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ], 64))
}