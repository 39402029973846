
  import { defineComponent, onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'

  export default defineComponent({
    name: 'kt-menu',
    setup () {
      const route = useRoute()
      const scrollElRef = ref<null | HTMLElement>(null)

      onMounted(() => {
        if (scrollElRef.value) {
          scrollElRef.value.scrollTop = 0
        }
      })

      const hasActiveChildren = (match) => route.path.indexOf(match) !== -1
      const asideMenuIcons = 'font'
      
      return {
        hasActiveChildren,
        asideMenuIcons,
        trans,
        transChoice,
        capitalize
      }
    }
  })
