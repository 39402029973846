
  import { defineComponent } from 'vue'
  import KTMenu from '@src/components/layout/aside/Menu.vue'
  import Logo from '@src/components/core/Logo.vue'

  export default defineComponent({
    name: 'KTAside',
    components: {
      KTMenu,
      Logo
    },
    props: {
      lightLogo: {
        type: String,
        default: ''
      },
      darkLogo: {
        type: String,
        default: ''
      }
    },
    setup () {
      const asideTheme = 'light'
      
      return {
        asideTheme
      }
    }
  })
