
  import { defineComponent } from 'vue'
  import KTTopbar from '@src/components/layout/header/Topbar.vue'

  export default defineComponent({
    name: 'KTHeader',
    props: {
      breadcrumbs: Array,
      title: String
    },
    components: {
      KTTopbar
    },
    setup () {
      const headerWidthFluid = true
      const headerLeft = true
      const asideDisplay = true
      return {
        headerWidthFluid,
        headerLeft,
        asideDisplay
      }
    }
  })
