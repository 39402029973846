
  import { defineComponent, defineAsyncComponent, ref, computed, onMounted } from 'vue'
  import { hideModal } from '@src/core/helpers/dom'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'

  import useFormValidation from '@src/use/formValidation'
  import useVuelidate from '@vuelidate/core'
  import { required, minLength, helpers } from '@vuelidate/validators'

  import objectPath from 'object-path'
  import fb from '@src/firebaseConfig'

  interface TemplateData {
    name: string
    desc: string
    customer: number
  }

  export default defineComponent({
    name: 'ModalTemplateAdd',
    components: {
      Loader: defineAsyncComponent(() => import('@src/components/content/CustomLoader.vue'))
    },
    setup () {
      // const submitButtonRef = ref<null | HTMLButtonElement>(null)
      const modalRef = ref<null | HTMLElement>(null)
      const addTemplateModalRef = ref<null | HTMLElement>(null)

      const templateData = ref<TemplateData>({
        name: '',
        desc: '',
        customer: 0
      })

      const submitted = ref(false)
      const submitButtonAnimation = ref(false)
      const customers = ref([])
      const fetchedCustomers = ref(false)

      const initializeModal = () => {
        templateData.value.name = ''
        templateData.value.desc = ''
        templateData.value.customer = 0

        submitted.value = false
        submitButtonAnimation.value = false

        const getCustomers = fb.functions.httpsCallable('backendCustomersGetCustomers')

        getCustomers({
          data: {
            limit: 1000,
            orderBy: 'name',
            sort: 'asc'
          }
        }).then((res: any) => {
          console.log('Fetched customers: ', res.data.result)
          customers.value = res.data.result
          fetchedCustomers.value = true
        }).catch((err: any) => {
          console.log('Error occurred while fething the customers:', err)
        })
      }

      onMounted(() => {
        if (addTemplateModalRef.value !== null) {
          addTemplateModalRef.value.addEventListener('shown.bs.modal', () => {
            initializeModal()
          })
        }
      })

      const alphaAndHyphenAndApostrophe = helpers.regex(/^[0-9a-zÆØÅæøå'\-\s]*$/iu)

      const validationRules = {
        name: {
          required,
          alphaAndHyphenAndApostrophe,
          minLength: minLength(2)
        },
        desc: {
          required
        },
        customer: {
          required
        }
      }

      const v$ = useVuelidate(
        validationRules,
        templateData
      )

      const { validateState } = useFormValidation(v$, submitted)
      
      const saveTemplate = () => {
        submitted.value = true
        submitButtonAnimation.value = true

        console.log('VALUE: ', addTemplateModalRef.value)

        v$.value.$touch()
        if (Object.keys(v$.value.$errors).length > 0) {
          console.log('Form did not pass...')
          submitButtonAnimation.value = false
          return // Found errors, will be shown
        }

        console.log('Form passed :)')

        setTimeout(() => {
          console.log('Hiding modal....')
          hideModal(addTemplateModalRef.value)
        }, 2000)
      }

      const customersSelect = computed(() => {
        console.log('CUSTOMERS ARRAY:', customers.value)
        const customersArray = Object.keys(customers.value).map((customerKey) => ({
          value: objectPath.get(customers, `value.${ customerKey }.id`, null),
          text: objectPath.get(customers, `value.${ customerKey }.name`, `(${ trans('templates.modals.add.form.fields.customer.missing') })`) // eslint-disable-line max-len
        }))

        const standardChoice = [
          {
            value: 0,
            text: trans('templates.modals.add.form.fields.customer.everybody')
          }
        ]

        const tmp = [...standardChoice, ...customersArray]
        console.log('tmp:', tmp)
        return tmp
      })

      return {
        v$,
        trans,
        transChoice,
        capitalize,
        templateData,
        modalRef,
        addTemplateModalRef,
        saveTemplate,
        validateState,
        fetchedCustomers,
        submitButtonAnimation,
        customersSelect
      }
    }
  })
