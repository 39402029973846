import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "toolbar",
  id: "kt_toolbar"
}
const _hoisted_2 = {
  class: "d-flex align-items-center",
  id: "kt_header_menu_nav"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "kt_toolbar_container",
      class: _normalizeClass([{
        'container-fluid': _ctx.toolbarWidthFluid,
        'container-xxl': !_ctx.toolbarWidthFluid,
      }, "d-flex flex-stack"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getActiveSubmenu), { subdata: _ctx.getSubmenuData }, null, 8, ["subdata"]))
      ])
    ], 2)
  ]))
}