import { Modal } from 'bootstrap'

const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return
  }

  const myModal = Modal.getInstance(modalEl)
  myModal.hide()
}

const onModalShown = (modalEl: HTMLElement | null): void => {
  console.log('Running onModalShown', modalEl)
  if (!modalEl) {
    return
  }

  modalEl.addEventListener('shown.bs.modal', (e) => {
    console.log('Modal was shown', e)
  })
}

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll('.modal-backdrop.fade.show').length) {
    document.querySelectorAll('.modal-backdrop.fade.show').forEach((item) => {
      item.remove()
    })
  }
}

export { removeModalBackdrop, hideModal, onModalShown }
