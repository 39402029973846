
  import { defineComponent } from 'vue'

  // Vary imports (modified Metronic files)
  import KTUserMenu from '@src/components/layout/header/partials/UserMenu.vue'

  // Metronic imports
  // import KTNotificationsMenu from '@/layout/header/partials/NotificationsMenu.vue'

  export default defineComponent({
    name: 'topbar',
    components: {
      KTUserMenu
    }
  })
