
  import { defineComponent, computed } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import authStore from '@src/store/enums/auth'
  import objectPath from 'object-path'

  import { trans } from '@src/core/helpers/translate'

  export default defineComponent({
    name: 'kt-user-menu',
    components: {},
    setup () {
      const router = useRouter()
      const store = useStore()

      const userInfo = computed(() => store.getters[authStore.Getters.GET_ALL])
      const authUser = computed(() => {
        type User = {
          name: string,
          email: string
        }
        const user = {} as User
        const userData = userInfo.value

        console.log('userData: ', userData)

        user.name = objectPath.get(userData, 'data.displayName', trans('header.userMenu.unknownName'))
        user.email = objectPath.get(userData, 'data.email', trans('header.userMenu.unknownEmail'))

        return user
      })

      const signOut = () => {
        store
          .dispatch(authStore.Actions.LOGOUT_FROM_FB)
          .then(() => router.push({ name: 'login' }))
      }

      return {
        authUser,
        signOut,
        trans
      }
    }
  })
